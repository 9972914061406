@import 'src/utils/utils';

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: $Main-2;
  margin-right: 16px;
  margin-left: 16px;
  padding-top: 10px;
  padding-bottom: 10px;

  @media(min-width: $md) {
    border-radius: 20px;
  }
  @media(min-width: $lg) {
    margin-right: 0;
    margin-left: 0;
  }
  @media(min-width: $xxl) {
    @include box;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  svg {
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
}